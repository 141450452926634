import { Button } from 'antd';
import forbidden from '@/assets/403.svg';
import { history, useDispatch } from 'umi';
import { useIntl } from 'react-intl';

export default () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch({
      type: 'global/setData',
      payload: {
        token: '',
      },
    });
    localStorage.removeItem(TOKEN_NAME);
    window.location.href = `${SSO_LOGIN}/logout?app=${window.location.host}`;
  };

  const { formatMessage } = useIntl();

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <div style={{ margin: '240px auto', textAlign: 'center' }}>
        <img src={forbidden} style={{ width: 84, height: 100 }} />
        <div
          style={{
            fontSize: 30,
            color: '#212121',
            fontWeight: 500,
            marginTop: 48,
          }}
        >
          403 Forbidden
        </div>
        <div style={{ fontSize: 20, color: '#424242', marginTop: 16 }}>
          {formatMessage({
            id: 'You do not have the access to Admin Portal. Please contact the administrator to apply for a permission.',
          })}
        </div>
        <Button
          type="default"
          style={{ width: 200, marginTop: 24 }}
          onClick={handleLogout}
        >
          {formatMessage({ id: 'Log Out' })}
        </Button>
      </div>
    </div>
  );
};
