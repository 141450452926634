export default function getMenu(menus: Menu[], scope: Scope) {
  const topMenus: TopMenu[] = [];
  const sideMenus: SideMenu = {};

  (menus || []).forEach(({ title, key, path, children }) => {
    const _children = children.filter(
      ({ scopeKey }) => scopeKey === 'debug' || scope[scopeKey as string],
    );

    if (_children.length) {
      topMenus.push({
        title,
        key: key || title,
        path,
      });
      sideMenus[key || title] = _children;
    }
  });

  return {
    topMenus,
    sideMenus,
  };
}
