import { useContext, useEffect } from 'react';
import { MicroApp, Redirect, history } from 'umi';
import { useSelector, useDispatch } from 'dva';
import { CurrencyContext } from 'iglooform';
import request, { errorHandler } from '@/utils/request';
import upload from '@/utils/upload';
import { getEmail } from '@/utils/jwt';

export default function MyPage() {
  const {
    currentLang,
    currentCurrency,
    scope,
    currentPlatform,
    exchangeRate = {},
    defaultCurrency: defaultCurrencyState,
    menus: menusState,
    currencies: currenciesState,
    languages: languagesState,
    homeMenus: homeMenusState,
  } = useSelector<ReduxState, GlobalState>((state) => state.global);

  const initing = useSelector<ReduxState, boolean>(
    (state) => state.loading.effects['global/init'],
  );

  const token = localStorage.getItem(TOKEN_NAME);
  //exchange default currency to current currency

  const dispatch = useDispatch();

  const { exchange, exchangeBack } = useContext(CurrencyContext);

  useEffect(() => () => {
    sessionStorage.setItem(
      'redirect',
      `${location.pathname}${location.search}`,
    );
  });

  useEffect(
    () => () => {
      dispatch({
        type: 'global/setData',
        payload: {
          menus: [],
        },
      });

      sessionStorage.removeItem('redirect');
      sessionStorage.removeItem('selectedTopMenu');
    },
    [],
  );

  if (!currentPlatform) {
    return <Redirect to="/platforms" />;
  }

  const handleUpdateConfig = ({
    menus,
    currencies,
    defaultCurrency,
    languages,
    defaultLang,
    homeMenus,
    headerSimpleLayout,
    siderSimpleLayout,
  }: GlobalState) => {
    dispatch({
      type: 'global/setData',
      payload: {
        menus: menus || menusState || [],
        currencies: currencies || currenciesState || [],
        currentCurrency: defaultCurrency || currentCurrency,
        languages: languages || languagesState || [],
        currentLang: defaultLang || currentLang,
        subAppMounted: true,
        homeMenus: homeMenus || homeMenusState || [],
        defaultCurrency: defaultCurrency || defaultCurrencyState,
        headerSimpleLayout: headerSimpleLayout || [],
        siderSimpleLayout: siderSimpleLayout || [],
      },
    });
  };

  const handleUpdateLanguage = ({ languages, defaultLang }: any) => {
    dispatch({
      type: 'global/setData',
      payload: {
        languages,
        currentLang: defaultLang,
      },
    });
  };

  const handleUpdateCurrency = ({ currencies, defaultCurrency }: any) => {
    dispatch({
      type: 'global/setData',
      payload: {
        currencies,
        currentCurrency: defaultCurrency,
        defaultCurrency,
      },
    });
  };

  const logout = () => {
    dispatch({
      type: 'global/setData',
      payload: {
        token: '',
      },
    });
    localStorage.removeItem(TOKEN_NAME);
    history.push(`/login`);
  };

  if (initing) return null;

  return (
    <MicroApp
      email={getEmail()}
      name={currentPlatform as string}
      base="/platform"
      style={{ height: '100%' }}
      currentLang={currentLang}
      currentCurrency={currentCurrency}
      scope={scope}
      updateConfig={handleUpdateConfig}
      updateLanguage={handleUpdateLanguage}
      updateCurrency={handleUpdateCurrency}
      token={token}
      exchangeRate={exchangeRate}
      locale={currentLang}
      exchange={exchange}
      exchangeBack={exchangeBack}
      requestMethod={request}
      requestErrorHandler={errorHandler}
      uploadMethod={upload}
      logout={logout}
      apiPrefix={API_URL}
    />
  );
}
