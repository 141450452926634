import { FC } from 'react';
import { Space } from 'antd';
import { useSelector, useDispatch } from 'dva';
import { useIntl } from 'react-intl';
import { PlatformCard } from 'iglooform';
import styles from './index.less';
import { useEffect } from 'react';

const cardBadge: Record<string, string> = {
  uap: 'UAP',
  reconciliation: 'Reconciliation',
  'anti-fraud': 'Anti-Fraud',
};

const SelectPlatform: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { platforms } = useSelector<ReduxState, GlobalState>(
    (state) => state.global,
  );

  useEffect(() => {
    dispatch({
      type: 'global/setData',
      payload: {
        selectedTopMenu: null,
      },
    });
  }, []);

  const enterPlatform = (key: string) => {
    dispatch({
      type: 'global/getPermissions',
      payload: {
        currentPlatform: key,
      },
    });
  };

  const enterUapPlatform = (key: string, path: string) => {
    if (process.env.NODE_ENV === 'development') {
      // For local development, config in `.umirc.local.ts`
      const token = localStorage.getItem(TOKEN_NAME);
      window.location.href = `${UAP_DOMAIN}/${path}?platform=${key}&token=${token}`;
    } else {
      window.location.href = `${window.location.origin}/${path}?platform=${key}`;
    }
  };

  const onCardClick = (platform: {
    name: string;
    key: string;
    kind: string;
  }) => {
    if (['uap', 'reconciliation'].includes(platform.kind)) {
      enterUapPlatform(platform?.key, 'uap');
      return;
    }
    if (platform.kind === 'anti-fraud') {
      enterUapPlatform(platform?.key, 'anti-fraud');
      return;
    }

    enterPlatform(platform?.key);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {formatMessage({
          id: 'Please Select Platform',
          defaultMessage: 'Please Select Platform',
        })}
      </div>
      <Space className={styles.platforms} size={[40, 40]} wrap>
        {platforms?.map((platform) => (
          <PlatformCard
            key={platform?.key}
            onClick={() => onCardClick(platform)}
            platform={platform}
            badgeText={
              platform.kind in cardBadge ? cardBadge[platform.kind] : void 0
            }
          />
        ))}
      </Space>
    </div>
  );
};

export default SelectPlatform;
