import rawRequest from 'umi-request';
import request, { errorHandler } from '../utils/request';

export const loginMethods = (email: string) => {
  return rawRequest.get(`${API_URL}/v1/admin_account/login/account/methods`, {
    params: {
      email,
    },
    credentials: 'include',
    errorHandler,
  });
};

export const login = (email: string, password: string) => {
  return rawRequest.post(`${API_URL}/v1/admin_account/login/by_password`, {
    data: {
      email,
      password,
    },
    credentials: 'include',
    errorHandler,
  });
};

export const otp = (email: string, code?: string) => {
  if (!code) {
    return rawRequest.get(`${API_URL}/v1/admin_account/login/otp`, {
      params: {
        email,
      },
      credentials: 'include',
      errorHandler,
    });
  }

  return rawRequest.post(`${API_URL}/v1/admin_account/login/otp`, {
    data: {
      email,
      otp_code: code,
    },
    credentials: 'include',
    errorHandler,
  });
};

export const getPermissions = (platform: string) => {
  return request(`/v1/admin_account/account/platform/${platform}/permissions`, {
    errorHandler,
  });
};

export const getExchangeRate = (date: string) => {
  return rawRequest.get(`${CURRENCY_API_URL}?date=${date}`, {
    errorHandler,
  });
};

export const changePwd = (data: any) => {
  return request.post(`/v1/admin_account/account/reset-password`, {
    data,
    credentials: 'include',
    errorHandler,
  });
};

export const getPlatforms = (kind?: string) => {
  const queryString = kind ? `?kind=${kind}` : '';
  return request(`/v1/admin_account/account/platforms${queryString}`);
};

export const getActivityLog = (params: any) => {
  const { page, pageSize } = params;
  return request(
    `/v2/admin/fileapi/data_file/jobs?page=${page}&size=${pageSize}&view_mode=all`,
  );
};
