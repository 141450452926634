import { FC, useEffect } from 'react';
import { Table, Button, Typography } from 'iglooform';
import { AttachOutlined, DownloadOutlined, ArrowLeftOutlined } from 'iglooicon';
import { history } from 'umi';
import { Row, Col, Space } from 'antd';
import { useDispatch, useSelector } from 'dva';
import { useIntl } from 'react-intl';
import moment from 'moment';
import styles from './index.less';

function downLoadFile(name: string, url: string) {
  const link: any = document.createElement('a');
  link.download = name;
  link.href = url;
  link.click();
}

const ActivityLog: FC<any> = () => {
  const dispatch = useDispatch();

  const {
    list,
    activityLogLoading,
    total,
    page = 1,
    pageSize = 10,
  } = useSelector<ReduxState, ActivityLogState>((state) => ({
    ...state.activityLog,
    activityLogLoading: state.loading.effects['activityLog/getActivityLog'],
  }));
  const { formatMessage } = useIntl();
  const columns = [
    {
      title: formatMessage({ id: 'Time' }),
      key: 'create_at',
      dataIndex: 'create_at',
      render: (time: number) => {
        moment.locale('en');
        return moment(time).format('DD MMM YYYY, hh:mm:ss a');
      },
    },
    {
      title: formatMessage({ id: 'Action Type' }),
      key: 'data_source_id',
      dataIndex: 'data_source_id',
    },
    {
      title: formatMessage({ id: 'Status' }),
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: formatMessage({ id: 'Description' }),
      key: 'description',
      render: (_: any, record: any) => {
        const {
          origin_file_name,
          success_num,
          fail_num,
          status,
          file_url,
          fail_data_url,
          file_size,
        } = record;
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography level={'body1'}>
                {formatMessage(
                  {
                    id: 'File Name：{origin_file_name}',
                  },
                  {
                    origin_file_name,
                  },
                )}
              </Typography>
              <AttachOutlined
                style={{ fontSize: 24, marginLeft: 8 }}
                onClick={() => downLoadFile(origin_file_name, file_url)}
              />
            </div>
            <div>
              <Typography level={'body1'}>
                {formatMessage(
                  {
                    id: 'File Size: {file_size}',
                  },
                  {
                    file_size,
                  },
                )}
                M
              </Typography>
            </div>
            {status && (
              <Typography level="body1">
                {formatMessage(
                  {
                    id: 'Import Result：Success {success_num}; Error {fail_num}',
                  },
                  {
                    success_num,
                    fail_num,
                  },
                )}
              </Typography>
            )}
            {fail_data_url && (
              <Space className={styles.ErrorButton}>
                <Typography
                  level="h4"
                  onClick={() => downLoadFile('Error Report', fail_data_url)}
                >
                  {formatMessage({ id: 'Error Report' })}
                </Typography>
                <DownloadOutlined
                  onClick={() => downLoadFile('Error Report', fail_data_url)}
                  style={{ fontSize: 24 }}
                />
              </Space>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch({
      type: 'activityLog/getActivityLog',
      payload: {
        page: 1,
        pageSize: 10,
      },
    });
  }, []);

  function handlePagination(page: number, pageSize?: number) {
    dispatch({
      type: 'activityLog/setData',
      payload: {
        page,
        pageSize,
      },
    });
    dispatch({
      type: 'activityLog/getActivityLog',
      payload: {
        page,
        pageSize,
      },
    });
  }

  return (
    <div className={styles.activityLogContainer}>
      <Row className={styles.rowContainer} justify="center">
        <Col span={20}>
          <Button
            type="text"
            onClick={() => history.goBack()}
            icon={<ArrowLeftOutlined style={{ color: '#5858FF' }} />}
            className={styles.goBackButton}
          >
            <Typography level="h4" style={{ color: '#5858FF' }}>
              {formatMessage({
                id: 'Back to Dashboard',
                defaultMessage: 'Back to Dashboard',
              })}
            </Typography>
          </Button>
          <div style={{ margin: '24px 0px' }}>
            <Typography level="h2">
              {formatMessage({
                id: 'Activity Log',
                defaultMessage: 'Activity Log',
              })}
            </Typography>
          </div>

          <Table
            loading={activityLogLoading}
            total={total}
            columns={columns}
            dataSource={list}
            page={page}
            pageSize={pageSize}
            noCustomize={true}
            onPagination={handlePagination}
            rowKey={'job_id'}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ActivityLog;
