import { Redirect } from 'umi';

const Login = () => {
  const token = localStorage.getItem(TOKEN_NAME);

  if (token) {
    return <Redirect to="/platforms" />;
  } else {
    window.location.href = `${SSO_LOGIN}/login?app=${window.location.href}`;
  }
};

export default Login;
