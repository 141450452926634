import { Model } from 'dva';
import { getActivityLog } from '../../services/global';

type ActivityLogModel = Omit<Model, 'state'> & { state: ActivityLogState };

export default {
  namespace: 'activityLog',
  state: {
    list: [],
    total: 0,
    page: 1,
    pageSize: 10,
  },
  reducers: {
    setData(state, { payload }: any) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *getActivityLog({ payload }, { call, put }) {
      const res = yield call(getActivityLog, payload);
      const { data, total } = res;
      if (res) {
        yield put({
          type: 'setData',
          payload: {
            list: data,
            total,
          },
        });
      }
    },
  },
} as ActivityLogModel;
