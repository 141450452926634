import { extend, ResponseError } from 'umi-request';
import { notification } from 'antd';

export const errorHandler = async (error: ResponseError) => {
  const { response } = error;

  let rst: any;
  if (response && response.status) {
    const { status, url } = response;
    console.log(status, url);

    try {
      rst = await response.json();
    } catch {
      rst = null;
      console.log('non-json response');
    }

    if (status === 401) {
      const lastToken = localStorage.getItem(TOKEN_NAME);
      localStorage.removeItem(TOKEN_NAME);
      window.location.href = `${SSO_LOGIN}/logout?app=${window.location.href}&lastToken=${lastToken}`;
    }
  }

  notification.error({
    message: rst?.message || rst?.msg || 'No response from server.',
  });
};

const request = extend({
  prefix: API_URL,
  headers: {
    'X-Axinan-Authorization': localStorage.getItem(TOKEN_NAME) as string,
  },
  errorHandler,
});

export const updateRequest = () => {
  request.extendOptions({
    prefix: API_URL,
    headers: {
      'X-Axinan-Authorization': localStorage.getItem(TOKEN_NAME) as string,
    },
    errorHandler,
  });
};

export default request;
