export default function upload(
  dataSource: string,
  file: File,
  enableApiAuth: boolean,
  onProgess: (e: ProgressEvent) => any,
): {
  abort: () => any;
  result: Promise<{
    success: boolean;
    errorMessage?: string;
  }>;
} {
  const xhr = new XMLHttpRequest();

  xhr.open(
    'POST',
    enableApiAuth
      ? `${API_URL}/v2/platform/admin/fileapi/data_file/upload/${dataSource}`
      : `${API_URL}/v2/admin/fileapi/data_file/upload/${dataSource}`,
  );

  xhr.setRequestHeader(
    'X-Axinan-Authorization',
    localStorage.getItem(TOKEN_NAME) as string,
  );

  xhr.upload.onprogress = onProgess;

  const formData = new FormData();
  formData.append('file', file);

  xhr.send(formData);

  return {
    abort: () => xhr.abort(),
    result: new Promise((resolve, reject) => {
      xhr.upload.onerror = (e) => {
        reject({
          success: false,
        });
      };

      xhr.onload = (e) => {
        if (xhr.status === 200) {
          resolve({
            success: true,
          });
          return;
        }
        try {
          const res = JSON.parse(xhr.response);

          reject({
            success: false,
            errorMessage: res?.message,
          });
        } catch {
          reject({
            success: false,
            errorMessage: 'Request Failed.',
          });
        }
      };

      xhr.onabort = () =>
        reject({
          success: false,
        });
    }),
  };
}
