import { useEffect, useMemo, FC, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'dva';
import { history } from 'umi';
import { IntlProvider, useIntl, FormattedMessage } from 'react-intl';
import { RouteProps } from 'react-router-dom';
import { Layout, Space } from 'antd';
import SideBar from './sider';
import HeadBar from './header';
import getMenu from './menu';
import { CurrencyContext, Typography, LocaleProvider } from 'iglooform';
import styles from './index.less';
import id from '@/locales/id-ID';
import en from '@/locales/en-US';
import zh from '@/locales/zh-CN';
import vi from '@/locales/vi-VN';
import zhTW from '@/locales/zh-TW';
import classNames from 'classnames';
import { messages as sideMenuMessages } from './sider';
import _ from 'lodash';

const locales: any = {
  'id-ID': id,
  'en-US': en,
  'zh-CN': zh,
  'vi-VN': vi,
  'zh-TW': zhTW,
};

const getPageLabel = (
  topMenus: TopMenu[],
  selectedTopMenu: string,
  sideMenus: SideMenu,
  currentMenu: string,
  formatMessage: Function,
) => {
  const top = topMenus?.find(({ key }) => key === selectedTopMenu);

  if (!top) {
    return null;
  }

  const side = sideMenus[selectedTopMenu]?.find(
    ({ key }) => `/platform${key}` === currentMenu,
  );

  if (!side) {
    return null;
  }

  return (
    <div className={styles.pageLabel}>
      <Typography level="h2" style={{ color: '#666666' }}>
        {top.title || top.key}
      </Typography>
      <div className={styles.divider} />
      <Typography level="h2" style={{ color: '#212121' }}>
        {sideMenuMessages[side.title] ? (
          // ? formatMessage(sideMenuMessages[side.title])
          <FormattedMessage {...sideMenuMessages[side.title]} />
        ) : (
          side.title
        )}
      </Typography>
    </div>
  );
};

const BasicLayout: FC<RouteProps> = (props) => {
  const { location, children } = props;

  const { formatMessage } = useIntl();

  const {
    scope = {},
    menus,
    selectedTopMenu,
    currentLang,
    subAppMounted,
    token,
    permissionFetching,
    currentPlatform,
    exchangeRate = {},
    currentCurrency,
    defaultCurrency,
    headerSimpleLayout,
    siderSimpleLayout,
  } = useSelector<
    ReduxState,
    GlobalState & { loading: boolean; permissionFetching: boolean }
  >((state) => ({
    ...state.global,
    loading: state.loading.effects['global/init'],
    permissionFetching: state.loading.effects['global/getPermissions'],
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!token && !location?.pathname?.includes('login')) {
      window.location.href = `${SSO_LOGIN}/login?app=${window.location.href}`;
    }
  }, []);

  const logout = () => {
    dispatch({
      type: 'global/setData',
      payload: {
        token: '',
      },
    });
    localStorage.removeItem(TOKEN_NAME);
    window.location.href = `${SSO_LOGIN}/logout?app=${window.location.href}`;
  };

  useEffect(() => {
    if (!IS_PROD) return;

    if (token) {
      const throttled = _.throttle(
        () => {
          const now: any = Date.now();

          if (localStorage.getItem(TOKEN_NAME)) {
            localStorage.setItem('lastMouseMoveTime', now);
          } else {
            window.location.href = `${SSO_LOGIN}/logout?app=${window.location.href}`;
          }
        },
        5000,
        { trailing: true },
      );
      document.body.addEventListener('mousemove', throttled);

      const timer = setInterval(() => {
        if (
          Number(Date.now()) -
            Number(localStorage.getItem('lastMouseMoveTime')) >
          10 * 60 * 1000
        ) {
          logout();
        }
      }, 60000);

      return () => {
        clearInterval(timer);
        document.body.removeEventListener('mousemove', throttled);
        localStorage.removeItem('lastMouseMoveTime');
      };
    }
  }, [token]);

  const { topMenus, sideMenus } = useMemo(
    () => getMenu((menus || []) as Menu[], scope),
    [menus, scope, currentPlatform],
  );

  const currentSideMenus = sideMenus[selectedTopMenu || topMenus[0]?.key];

  const showSider =
    !permissionFetching &&
    subAppMounted &&
    !['/login', '/platforms', '/403'].includes(location?.pathname as string) &&
    !siderSimpleLayout?.includes(location?.pathname as string);

  const showHeader =
    !location?.pathname?.includes('403') &&
    !location?.pathname?.includes('login') &&
    !headerSimpleLayout?.includes(location?.pathname as string);

  const pageLabel = getPageLabel(
    topMenus,
    selectedTopMenu as string,
    sideMenus,
    location?.pathname as string,
    formatMessage,
  );

  useEffect(() => {
    if (!topMenus || !topMenus.length) return;

    const newSelectedTopMenu = selectedTopMenu || topMenus[0]?.key;

    if (selectedTopMenu !== newSelectedTopMenu) {
      dispatch({
        type: 'global/setData',
        payload: {
          selectedTopMenu: newSelectedTopMenu,
        },
      });
      sessionStorage.setItem('selectedTopMenu', newSelectedTopMenu);
    }
  }, [topMenus, sideMenus, showSider, selectedTopMenu]);

  const exchange = (value: number) => {
    if (!value && value !== 0) return value;

    const rate = (exchangeRate[defaultCurrency as string] || {})[
      currentCurrency as string
    ];

    return rate ? value * rate : value;
  };

  //exchange current currency to default currency
  const exchangeBack = (value: number) => {
    if (!value && value !== 0) return value;

    const rate = (exchangeRate[currentCurrency as string] || {})[
      defaultCurrency as string
    ];

    return rate ? value * rate : value;
  };

  const clickTopMenu = ({ key, item }: any) => {
    if (key === selectedTopMenu) {
      return;
    }

    dispatch({
      type: 'global/setData',
      payload: {
        selectedTopMenu: key,
      },
    });

    sessionStorage.setItem('selectedTopMenu', key);

    const topMenu = topMenus.find(({ key: menuKey }) => key === menuKey);

    if (topMenu?.path) {
      history.push(topMenu.path);
      return;
    }

    const firstInlineMenu = sideMenus[key].find(
      ({ path }) => !path.startsWith('http'),
    );

    // some path does not start with '/'
    history.push(`/platform/${firstInlineMenu?.path}`.replace('//', '/'));
  };

  return (
    <IntlProvider
      locale={currentLang || 'en-US'}
      messages={locales[currentLang || 'en-US'] || en}
    >
      <CurrencyContext.Provider
        value={{
          exchange,
          exchangeBack,
          currentCurrency: currentCurrency as string,
        }}
      >
        <LocaleProvider currentLang={currentLang || 'en-US'}>
          <Layout className={styles.layout}>
            {showHeader && (
              <Layout.Header className={styles.header}>
                <HeadBar
                  topMenus={topMenus}
                  showSwitchPlatform={showSider}
                  selectedTopMenu={selectedTopMenu as string}
                  onMenuChange={clickTopMenu}
                />
              </Layout.Header>
            )}
            <Layout style={{ overflow: 'hidden', background: '#FFF' }}>
              {showSider && (
                <Layout.Sider className={styles.sider} width={212}>
                  <SideBar
                    menus={currentSideMenus}
                    currentMenu={location?.pathname}
                  />
                </Layout.Sider>
              )}
              <Layout.Content
                className={classNames({
                  [styles.content]: true,
                  [styles.noPadding]: !showSider && !showHeader,
                })}
                key={currentPlatform}
                id={currentPlatform}
              >
                {pageLabel}
                <div
                  className={classNames({
                    [styles.subAppPage]: !pageLabel,
                    [styles.subAppPageWithLabel]: pageLabel,
                    [styles.ajustHeight]: showSider,
                  })}
                >
                  {children}
                </div>
              </Layout.Content>
            </Layout>
          </Layout>
        </LocaleProvider>
      </CurrencyContext.Provider>
    </IntlProvider>
  );
};

export default BasicLayout;
