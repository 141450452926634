import { Model } from 'dva';
import {
  login,
  otp,
  getExchangeRate,
  getPermissions,
  changePwd,
  getPlatforms,
  getActivityLog,
  loginMethods,
} from '../services/global';
import { updateRequest } from '@/utils/request';
import { history } from 'umi';
import { setMasterOptions } from '@@/plugin-qiankun/masterOptions';
import moment from 'moment';
import { message, notification } from 'antd';

type GlobalModel = Omit<Model, 'state'> & { state: GlobalState };

export default {
  namespace: 'global',
  state: {
    list: [],
    total: 0,
    page: 1,
    pageSize: 10,
    languages: [
      {
        title: 'EN',
        key: 'en-US',
      },
      {
        title: 'ID',
        key: 'id-ID',
      },
      {
        title: 'VN',
        key: 'vi-VN',
      },
      {
        title: 'ZH',
        key: 'zh-CN',
      },
    ],
    currentLang: 'en-US',
    subAppMounted: false,
    currentPlatform: sessionStorage.getItem('currentPlatform'),
    selectedTopMenu: sessionStorage.getItem('selectedTopMenu'),
    token: localStorage.getItem(TOKEN_NAME),
    loginMethods: [],
  },
  reducers: {
    setData(state, { payload }: any) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *loginMethods({ payload }, { call, put }) {
      const { methods } = yield call(loginMethods, payload);
      yield put({
        type: 'setData',
        payload: {
          loginMethods: methods,
        },
      });
    },

    *login({ payload }, { call, put, select }) {
      const { email, password } = payload;
      const { user_token: token } = yield call(login, email, password);

      if (!token) return;

      localStorage.setItem(TOKEN_NAME, token);

      updateRequest();

      yield put({
        type: 'setData',
        payload: {
          token,
        },
      });

      yield put({
        type: 'init',
      });
    },
    *otp(action, { call, put, select }) {
      const { email, code } = action.payload;
      let { user_token: token } = yield call(otp, email, code);

      if (!token) return;

      localStorage.setItem(TOKEN_NAME, token);
      updateRequest();
      yield put({
        type: 'setData',
        payload: {
          token,
        },
      });

      yield put({
        type: 'init',
      });
    },
    *init(_, { put, take }) {
      const redirect = sessionStorage.getItem('redirect');
      const platform = sessionStorage.getItem('currentPlatform');

      yield put({
        type: 'getExchangeRate',
      });

      yield put({
        type: 'getPlatforms',
        payload: {
          toPlatforms: !redirect,
        },
      });

      yield take('getPlatforms/@@end');

      if (redirect && platform) {
        yield put({
          type: 'getPermissions',
          payload: {
            redirect,
            currentPlatform: sessionStorage.getItem('currentPlatform'),
          },
        });

        yield take('getPermissions/@@end');
      }
    },
    *getPlatforms({ payload }, { call, put, all }) {
      const [
        { platforms: uapPlatforms },
        { platforms: reconPlatforms },
        { platforms: v3Platforms },
        { platforms: antiFraudPlatforms },
      ] = yield all([
        call(getPlatforms, 'uap'),
        call(getPlatforms, 'reconciliation'),
        call(getPlatforms, 'admin_v3'),
        call(getPlatforms, 'anti-fraud'),
      ]);

      if (
        !uapPlatforms ||
        !v3Platforms ||
        !reconPlatforms ||
        !antiFraudPlatforms
      )
        return;

      const platforms = [
        ...uapPlatforms,
        ...reconPlatforms,
        ...antiFraudPlatforms,
        ...v3Platforms,
      ];

      yield put({
        type: 'setData',
        payload: {
          platforms,
        },
      });
      if (process.env.NODE_ENV !== 'development') {
        setMasterOptions({
          apps: platforms.map(({ key }: any) => ({
            name: key,
            entry: `${window.location.origin}/static/${key}/index.html`,
            container: '#content',
            activeRule: `/${key}`,
          })),
        });
      }

      if (payload.toPlatforms) {
        history.push('/platforms');
      }
    },
    *getPermissions({ payload }, { call, select, put }) {
      yield put({
        type: 'setData',
        payload: {
          subAppMounted: false,
        },
      });

      const { currentPlatform, redirect } = payload;
      sessionStorage.setItem('currentPlatform', currentPlatform);

      const { permissions } = yield call(getPermissions, currentPlatform);

      if (!permissions) {
        message.error('Get permissions failed!');
        return;
      }

      const scope: any = {};

      permissions.forEach(({ key }: any) => {
        scope[key] = true;
      });

      yield put({
        type: 'setData',
        payload: {
          scope,
          currentPlatform,
        },
      });

      history.push(redirect || '/platform');
    },
    *getExchangeRate(_, { call, put }) {
      const { rates } = yield call(
        getExchangeRate,
        moment().format('YYYY-MM-DD'),
      );

      if (!rates) {
        return;
      }

      const exchangeRate: any = {};

      Object.keys(rates).forEach((base) => {
        const baseValue = rates[base];
        exchangeRate[base] = {};

        Object.keys(rates).forEach((target) => {
          const targetValue = rates[target];
          exchangeRate[base][target] = baseValue ? targetValue / baseValue : 1;
        });
      });

      yield put({
        type: 'setData',
        payload: {
          exchangeRate,
        },
      });
    },
    *changeCurrency({ payload }, { select, put }) {
      const { currentCurrency } = payload;
      const { defaultCurrency, exchangeRate } = yield select(
        (state: ReduxState) => state.global,
      );

      if (
        !exchangeRate ||
        !exchangeRate[defaultCurrency] ||
        !exchangeRate[defaultCurrency][currentCurrency]
      ) {
        notification.info({
          message: 'Cannot exchange',
        });
        return;
      }

      yield put({
        type: 'setData',
        payload: {
          currentCurrency,
        },
      });
    },
    *changePwd({ payload }, { call }) {
      const { callback, ...rest } = payload;

      yield call(changePwd, rest);

      if (typeof callback === 'function') {
        callback();
      }
    },
    *getActivityLog({ payload }, { call, put }) {
      const res = yield call(getActivityLog, payload);
      const { data, total } = res;
      if (res) {
        yield put({
          type: 'setData',
          payload: {
            list: data,
            total,
          },
        });
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }: any) {
      const { token, redirect, platform } = history.location.query;
      if (token) {
        localStorage.setItem(TOKEN_NAME, token);
        updateRequest();
        dispatch({
          type: 'setData',
          payload: {
            token,
          },
        });
      }

      if (platform) {
        sessionStorage.setItem('currentPlatform', platform);
      }

      if (redirect) {
        sessionStorage.setItem('redirect', redirect);
      }

      if (localStorage.getItem(TOKEN_NAME)) {
        dispatch({
          type: 'init',
        });
      }
    },
  },
} as GlobalModel;
