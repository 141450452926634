import { FC, useState, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { Menu, Dropdown, Input } from 'antd';
import { ArrowDownOutlined, AccountFilled } from 'iglooicon';
import { history } from 'umi';
import { useIntl } from 'react-intl';
import {
  Button,
  Modal,
  SwitchPlatform,
  Select,
  Typography,
  message,
} from 'iglooform';
import { useDispatch, useSelector } from 'dva';
import { getEmail } from '@/utils/jwt';
import { changePwd } from '@/services/global';
import SwitchLang from '@/components/switch-lang';
import logo from '@/assets/igloo.svg';
import styles from './index.less';

const Header: FC<{
  showSwitchPlatform: boolean;
  topMenus: TopMenu[];
  selectedTopMenu: string;
  onMenuChange: (menuItem: any) => any;
}> = ({ showSwitchPlatform, topMenus, selectedTopMenu, onMenuChange }) => {
  const { formatMessage } = useIntl();
  const [showChangePwd, setShowChangePwd] = useState(false);
  const dispatch = useDispatch();
  const {
    platforms,
    currentPlatform,
    homeMenus,
    currencies,
    currentCurrency,
    exchangeRate,
    loading,
  } = useSelector<ReduxState, GlobalState & { loading: boolean }>((state) => ({
    ...state.global,
    loading: state.loading.effects['global/init'],
  }));
  const email = getEmail();

  const { run: handleChangePwd } = useRequest(changePwd, {
    manual: true,
    onSuccess: (res) => {
      res &&
        message.success(
          formatMessage({
            id: 'Password reset successfully',
          }),
        );
    },
  });

  const changePlatform = (newPlatform: string) => {
    if (currentPlatform === newPlatform) {
      return;
    }

    dispatch({
      type: 'global/setData',
      payload: {
        selectedTopMenu: null,
        menus: [],
      },
    });
    sessionStorage.removeItem('selectedTopMenu');

    dispatch({
      type: 'global/getPermissions',
      payload: {
        currentPlatform: newPlatform,
      },
    });
  };

  const logout = () => {
    dispatch({
      type: 'global/setData',
      payload: {
        token: '',
      },
    });
    localStorage.removeItem(TOKEN_NAME);
    window.location.href = `${SSO_LOGIN}/logout?app=${window.location.href}`;
  };

  const handleCurrencyChange = (currentCurrency?: any) => {
    dispatch({
      type: 'global/changeCurrency',
      payload: {
        currentCurrency,
      },
    });
  };

  return (
    <div className={styles.headerContent}>
      <div className={styles.leftBox}>
        <div
          className={styles.logoBox}
          onClick={() => {
            !loading && history.push('/platforms');
          }}
        >
          <img src={logo} className={styles.logo} />
          Admin Panel
        </div>
        <div className={styles.divider} />
        {showSwitchPlatform && (
          <SwitchPlatform
            platforms={platforms || []}
            currentPlatform={currentPlatform as string}
            onChange={changePlatform}
          />
        )}
        <div className={styles.menuBox}>
          {topMenus?.length > 1 && (
            <Menu
              mode="horizontal"
              className={styles.menu}
              selectedKeys={[selectedTopMenu]}
              onClick={onMenuChange}
              disabledOverflow
            >
              {topMenus.map(({ key }) => (
                <Menu.Item key={key}>{key}</Menu.Item>
              ))}
            </Menu>
          )}
        </div>
      </div>
      <div className={styles.rightBox}>
        <SwitchLang className={styles.switchLang} />
        {showSwitchPlatform && (
          <Select
            options={currencies?.map(({ title, key }) => ({
              label: title,
              value: key,
            }))}
            className={styles.switchCurrency}
            disabled={!exchangeRate || !Object.keys(exchangeRate)}
            value={currentCurrency}
            onChange={handleCurrencyChange}
            size="small"
          />
        )}
        <Dropdown
          overlay={
            <div className={styles.popContent}>
              {homeMenus?.map(({ label, onClick }, index) => (
                <div className={styles.opItem} onClick={onClick} key={index}>
                  {formatMessage({ id: label })}
                </div>
              ))}
              <div
                className={styles.opItem}
                onClick={() => history.push('/activityLog')}
              >
                {formatMessage({
                  id: 'Activity Log',
                  defaultMessage: 'Activity Log',
                })}
              </div>
              <div
                className={styles.opItem}
                onClick={() => setShowChangePwd(true)}
              >
                {formatMessage({
                  id: 'Reset Password',
                  defaultMessage: 'Reset Password',
                })}
              </div>
              <div className={styles.opItem} onClick={logout}>
                {formatMessage({
                  id: 'Log Out',
                })}
              </div>
            </div>
          }
          trigger={['click']}
          getPopupContainer={(trigger) =>
            trigger.parentElement || document.body
          }
        >
          <div className={styles.dropdown}>
            <AccountFilled className={styles.accountIcon} />
            <span className={styles.email}>{email}</span>
            <ArrowDownOutlined className={styles.arrowIcon} />
          </div>
        </Dropdown>
      </div>
      <ChangePassword
        visible={showChangePwd}
        onClose={() => setShowChangePwd(false)}
        onOk={(pwd: any) =>
          handleChangePwd({
            ...pwd,
            email,
          })
        }
      />
    </div>
  );
};

export default Header;

const ChangePassword: FC<{
  visible: boolean;
  onClose: any;
  onOk: any;
}> = ({ visible, onClose, onOk }) => {
  const passReg =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[{}+=()\[\]~`!@#$%^&*\\\:;"'<,>.?/\|_-]).{8,}$/;
  const chineseReg = /[\u4e00-\u9fa5]/;
  const [newPwd, setNewPwd] = useState<string>();
  const { formatMessage } = useIntl();
  const [pwdInconsistent, setPwdInconsistent] = useState(false);

  const requiredLocale = formatMessage({
    id: 'is required',
  });

  const validateMessages = {
    required: '${label}' + requiredLocale,
  };

  const handleConfirm = (value: any) => {
    const { confirmPwd, newPwd, oldPwd } = value;
    if (confirmPwd !== newPwd) {
      setPwdInconsistent(true);
      return;
    }
    onClose();
    onOk({
      old_password: oldPwd,
      new_password: newPwd,
    });
  };

  return (
    <Modal
      visible={visible}
      destroyOnClose
      onCancel={onClose}
      onOk={handleConfirm}
      title={formatMessage({
        id: 'Reset Password',
        defaultMessage: 'Reset Password',
      })}
      mode="form"
      okText={formatMessage({
        id: 'Confirm',
        defaultMessage: 'Confirm',
      })}
      cancelText={formatMessage({
        id: 'Cancel',
        defaultMessage: 'Cancel',
      })}
      formProps={{
        locales: { validateMessages },
        initialValues: {},
      }}
      formConfig={{
        type: 'Page',
        elements: [
          {
            type: 'Password',
            name: 'oldPwd',
            fullRow: true,
            label: formatMessage({
              id: 'Old Password',
              defaultMessage: 'Old Password',
            }),
          },
          {
            type: 'Password',
            name: 'newPwd',
            fullRow: true,
            extra: formatMessage({
              id: 'Password must be at least 8 characters and contain four character types: digits (0-9), symbols, Uppercase characters (A-Z), Lowercase characters (a-z).',
            }),
            label: formatMessage({
              id: 'New Password',
              defaultMessage: 'New Password',
            }),
            rules: [
              {
                message: formatMessage({
                  id: 'Your password doesn’t meet password strength strategy, please change into another one.',
                  defaultMessage:
                    'Your password doesn’t meet password strength strategy, please change into another one.',
                }),
                validator: (rule, value) => {
                  if (!value) return Promise.resolve();
                  if (!/^[^\s]*$/.test(value)) return Promise.reject();
                  if (passReg.test(value) && !chineseReg.test(value)) {
                    setNewPwd(value);
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
              },
            ],
          },
          {
            type: 'Password',
            name: 'confirmPwd',
            fullRow: true,
            label: formatMessage({
              id: 'Confirm New Password',
              defaultMessage: 'Confirm New Password',
            }),
            rules: [
              {
                message: formatMessage({
                  id: 'The two passwords are inconsistent',
                  defaultMessage: 'The two passwords are inconsistent',
                }),
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  if (value === newPwd) {
                    setPwdInconsistent(false);
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
              },
            ],
          },
          {
            shouldRender: () => pwdInconsistent,
            render: () => (
              <Typography level="caption2">
                {formatMessage({
                  id: 'The two passwords are inconsistent',
                  defaultMessage: 'The two passwords are inconsistent',
                })}
              </Typography>
            ),
          },
        ],
      }}
    ></Modal>
  );
};
