import { FC } from 'react';
import { useSelector, useDispatch } from 'dva';
import { Select } from 'iglooform';

const SwitchLang: FC<any> = (props) => {
  const { languages, currentLang } = useSelector<ReduxState, GlobalState>(
    (state) => state.global,
  );
  const dispatch = useDispatch();

  const handleChange = (currentLang: string) => {
    dispatch({
      type: 'global/setData',
      payload: {
        currentLang,
      },
    });
  };

  return (
    <Select
      value={currentLang as string}
      {...props}
      size="small"
      onChange={handleChange}
    >
      {languages.map(({ title, key }) => (
        <Select.Option key={key} value={key}>
          {title}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SwitchLang;
