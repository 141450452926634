import { FC } from 'react';
import { Layout, Menu } from 'antd';
import { history } from 'umi';
import { useIntl, defineMessages } from 'react-intl';
import {
  PolicyOutlined,
  ClaimOutlined,
  ReimburseOutlined,
  InsureOutlined,
  InvoiceOutlined,
  RefundOutlined,
  BillOutlined,
  ReportOutlined,
  ShopOutlined,
  IglooOutlined,
  OverviewOutlined,
  QuoteOutlined,
  AttachOutlined,
  PartnerOutlined,
  PicOutlined,
  IndustryOutlined,
  ProductTypeOutlined,
  ProjectOutlined,
  TemplateOutlined,
  AccountOutlined,
  ClientOutlined,
  SurveyOutlined,
  CSVOutlined,
} from 'iglooicon';
import styles from './index.less';

export const messages: any = defineMessages({
  Overview: { id: 'Overview', defaultMessage: 'Overview' },
  Order: { id: 'Order', defaultMessage: 'Order' },
  Insurer: { id: 'Insurer', defaultMessage: 'Insurer' },
  Insure: { id: 'Insure', defaultMessage: 'Insure' },
  Invoice: { id: 'Invoice', defaultMessage: 'Invoice' },
  Quote: { id: 'Quote', defaultMessage: 'Quote' },
  Policy: { id: 'Policy', defaultMessage: 'Policy' },
  Claim: { id: 'Claim', defaultMessage: 'Claim' },
  Reimburse: { id: 'Reimburse', defaultMessage: 'Reimburse' },
  Reconciliation: { id: 'Reconciliation', defaultMessage: 'Reconciliation' },
  Refund: { id: 'Refund', defaultMessage: 'Refund' },
  Upload: { id: 'Upload', defaultMessage: 'Upload' },
  User: { id: 'User', defaultMessage: 'User' },
  'Bank Card': { id: 'Bank Card', defaultMessage: 'Bank Card' },
  'Payment Way': { id: 'Payment Way', defaultMessage: 'Payment Way' },
  Bill: { id: 'Bill', defaultMessage: 'Bill' },
  'Shop-Overview': { id: 'Shop-Overview', defaultMessage: 'Shop-Overview' },
  'Shop-Invoice': { id: 'Shop-Invoice', defaultMessage: 'Shop-Invoice' },
  'Shop-Refund': { id: 'Shop-Refund', defaultMessage: 'Shop-Refund' },
  'Shop-Report': { id: 'Shop-Report', defaultMessage: 'Shop-Report' },
  'Shop-Manage': { id: 'Shop-Manage', defaultMessage: 'Shop-Manage' },
  Shop: { id: 'Shop', defaultMessage: 'Shop' },
  Dashboard: { id: 'Dashboard', defaultMessage: 'Dashboard' },
  Device: { id: 'Device', defaultMessage: 'Device' },
  Repairshop: { id: 'Repairshop', defaultMessage: 'Repairshop' },
  Link: { id: 'Link', defaultMessage: 'Link' },
  Partner: { id: 'Partner', defaultMessage: 'Partner' },
  Pic: { id: 'Pic', defaultMessage: 'Pic' },
  Industry: { id: 'Industry', defaultMessage: 'Industry' },
  ProductType: { id: 'ProductType', defaultMessage: 'ProductType' },
  Project: { id: 'Project', defaultMessage: 'Project' },
  Template: { id: 'Template', defaultMessage: 'Template' },
  Account: { id: 'Account', defaultMessage: 'Account' },
  Client: { id: 'Client', defaultMessage: 'Client' },
  Survey: { id: 'Survey', defaultMessage: 'Survey' },
  'Activity Log': { id: 'Activity Log', defaultMessage: 'Activity Log' },
  'Capital Account': {
    id: 'Capital Account',
    defaultMessage: 'Capital Account',
  },
  'Insurance Stats': {
    id: 'Insurance Stats',
    defaultMessage: 'Insurance Stats',
  },
  'Sales Control': { id: 'Sales Control', defaultMessage: 'Sales Control' },
});

const icons = {
  Policy: PolicyOutlined,
  Claim: ClaimOutlined,
  Overview: OverviewOutlined,
  Reimburse: ReimburseOutlined,
  Insure: InsureOutlined,
  Insurer: InsureOutlined,
  Invoice: InvoiceOutlined,
  Refund: RefundOutlined,
  Bill: BillOutlined,
  Report: ReportOutlined,
  Shop: ShopOutlined,
  Quote: QuoteOutlined,
  Partner: PartnerOutlined,
  Pic: PicOutlined,
  Industry: IndustryOutlined,
  ProductType: ProductTypeOutlined,
  Project: ProjectOutlined,
  Template: TemplateOutlined,
  Account: AccountOutlined,
  default: IglooOutlined,
  Link: AttachOutlined,
  Client: ClientOutlined,
  Survey: SurveyOutlined,
  CSV: CSVOutlined,
} as any;

const getSelectedMenu = (menus: MenuItem[], currentMenu: string) => {
  const menu = menus.find(({ path }) => currentMenu.includes(path));

  return menu?.key ? [menu.key] : [];
};

const Sider: FC<{
  menus: MenuItem[];
  currentMenu?: string;
}> = ({ menus = [], currentMenu = '' }) => {
  const { formatMessage } = useIntl();

  const clickMenu = ({ key, item }: any) => {
    const { path } = item.props['data-item'];
    if (path.startsWith('http')) {
      window.open(path);
      return;
    }

    history.push(`/platform${key}`);
  };

  return (
    <div className={styles.container}>
      <Menu
        mode="inline"
        className={styles.menu}
        selectedKeys={getSelectedMenu(menus, currentMenu)}
        onClick={clickMenu}
      >
        {menus?.map((item) => {
          const Icon = icons[item.icon] || icons.default;
          const title = messages[item.title]
            ? formatMessage(messages[item.title])
            : item.title;

          return (
            <Menu.Item
              key={item.key}
              className={styles.menuItem}
              data-item={item}
              title={title}
            >
              <Icon className={styles.icon} />
              <span className={styles.text}>{title}</span>
            </Menu.Item>
          );
        })}
      </Menu>
    </div>
  );
};

export default Sider;
